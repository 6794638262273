import ContactForm from "../components/ContactForm";

const ContactPage = () => {
    return(
        <>
            <ContactForm />
        </>
    );
}

export default ContactPage;