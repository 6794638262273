import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import NotFoundPage from "./NotFoundPage";
import CommentsList from "../components/CommentsList";
import AddCommentForm from "../components/AddCommentForm"
import ideas from "./ideas-content";
import useUser from "../hooks/useUser";


const IdeaPage = () =>{
    const [ideaInfo, setIdeaInfo] = useState({ upvotes: 0, comments: [], canUpvote: false });
    const { canUpvote } = ideaInfo;
    const { ideaId } = useParams();

    const { user, isLoading } = useUser();
    const navigate = useNavigate();

 
    useEffect(() => {
        const loadIdeaInfo = async () => {
            const token = user && await user.getIdToken();
            const headers = token ? { authtoken: token } : {};
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ideas/${ideaId}`, { headers });
            const newIdeaInfo = response.data;
            setIdeaInfo(newIdeaInfo);
        }
        
        if(!isLoading){
            loadIdeaInfo();
        }
       
    }, [ideaId, user, isLoading]);

    
    const idea = ideas.find(idea => idea.name === ideaId); 

    const addUpvote = async () => {
        const token = user && await user.getIdToken();
        const headers = token ? { authtoken: token } : {};
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/ideas/${ideaId}/upvote`, null, { headers });
        const updatedIdea = response.data;
        setIdeaInfo(updatedIdea);
    }

    const goToLogin = () => {
        navigate('/login');
    }

    if(!idea){
        return <NotFoundPage />
    }
    
    //Add it to check if can votr or is loading
    //    <p>canUpvote: {canUpvote ? 'true' : 'false' }</p>
    //    <p>isLoading: {isLoading ? 'true' : 'false'}</p>  
    
    return(
        <>
        <h1>{idea.title}</h1>
      
        <div className="upvotes-section">
            {user
                ? <button onClick={addUpvote}>{canUpvote ? 'Upvote' : 'Already Upvoted'}</button>
                : <button onClick={goToLogin}>Login to upvote</button> }
            <p>This idea has {ideaInfo.upvotes} upvote(s)</p>
        </div>
        {idea.content.map((paragraph, i) => (
            <p key={i}>{paragraph}</p>
        ))}
        <br/>
        {user
                ? <AddCommentForm
                    ideaName={ideaId}
                    onIdeaUpdated={updatedIdea => setIdeaInfo(updatedIdea)} />
                : <button onClick={goToLogin}>Log in to add a commnet</button>}
        <CommentsList comments={ideaInfo.comments} />
        </> 
    );
}

export default IdeaPage;