import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import TicTacToePage from './pages/TicTacToePage';
import PingPongPage from './pages/PingPongPage';
import NotFoundPage from './pages/NotFoundPage';
import ContactPage from './pages/ContactPage';
import LoginPage from './pages/LoginPage';
import CreateAccountPage from './pages/CreateAccountPage';
import IdeasListPage from './pages/IdeasListPage';
import IdeaPage from './pages/IdeaPage';
import WeatherPage from './pages/WeatherPage';

function App() {
  return (
    <BrowserRouter>
    <div className="app">
      <NavigationBar/>
        <div className='page-body'>
          <div className='page-content'>
            <Routes>
              <Route path="/" element={<HomePage/>} />
              <Route path="/about" element={<AboutPage/>} />
              <Route path="/apps/weather" element={<WeatherPage/>} />
              <Route path="/games/tictactoe" element={<TicTacToePage/>} />
              <Route path="/games/pingpong" element={<PingPongPage/>} />
              <Route path="/contact" element={<ContactPage/>} />
              <Route path="/ideas" element={<IdeasListPage/>} />
              <Route path="/ideas/:ideaId" element={<IdeaPage/>} />
              <Route path="/login" element={<LoginPage/>} />
              <Route path="/create-account" element={<CreateAccountPage />} />
              <Route path="*" element={<NotFoundPage/>} />
            </Routes>
          </div>
          <Footer />
        </div>
    </div>    
    </BrowserRouter>
  );
}

export default App;
