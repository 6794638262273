import { Link } from 'react-router-dom';

const IdeasList = ({ ideas }) => {
    return (
        <>
        {ideas.map(idea => (
            <Link key={idea.name} className="idea-list-item" to={`/ideas/${idea.name}`}>
                <div className="idea-list-container">
                    <h3>{idea.title}</h3>
                    <p>{idea.content[0].substring(0, 150)}...</p>
                </div>
            </Link>
        ))}
        </>
    );
}

export default IdeasList;