const ideas = [
    {
        name: 'app-idea',
        title: 'Application Ideas',
        content: [
            `Hello! Here is a place where you can share your application ideas about the apps on 
            this website. This web app has been recently developed and I am aware there 
            are not a lot of apps to engage people. Because of that, I am open to 
            hearing any ideas regarding that. `,
            `Feel free to suggest any application ideas. It can be an app that is your favourite,
            or something that you would like to see. It can be a suggestion for the 
            improvement of an existing app. If the idea is liked by other people,
            or you share the same concept, I will try my best to implement it.
            Do not discourage if someone already wrote the idea. If we see it more 
            times it shows that people really like it and it should be done. `,
            `Developing high-quality software is not a black-and-white affair,
            but an ongoing process. As developers, we should be prepared to
            perform multiple iterations and other adjustments based on the 
            analysis and feedback from others.`,
            `Please share your ideas in the comments bellow! :)`,
        ]
    },    {
        name: 'game-idea',
        title: 'Game Ideas',
        content: [
            `Hello! Here is a place where you can share your game ideas about the games on 
            this website. This web app has been recently developed and I am aware there 
            are not a lot of games to engage people. Because of that, I am open to 
            hearing any ideas regarding that. `,
            `Feel free to suggest any game ideas. It can be an game that is your favourite,
            or something that you would like to see. It can be a suggestion for the 
            improvement of an existing game. If the idea is liked by other people,
            or you share the same concept, I will try my best to implement it.
            Do not discourage if someone already wrote the idea. If we see it more 
            times it shows that people really like it and it should be done. `,
            `Developing high-quality software is not a black-and-white affair,
            but an ongoing process. As developers, we should be prepared to
            perform multiple iterations and other adjustments based on the 
            analysis and feedback from others,`,
            `Please share your ideas in the comments bellow! :)`,
        ]
    },     {
        name: 'other-idea',
        title: 'Other Ideas',
        content: [
            `Hello! Here is a place where you can share your ideas about my web apllication on 
            which you are currently on. This web app has been recently developed and I am aware there 
            are not a lot of things to engage people. Because of that, I am open to 
            hearing any ideas regarding that. `,
            `Feel free to suggest any  ideas. It can be an something that you do not like,
            or something that you would like to see. It can be a suggestion for the 
            improvement of an existing page. If the idea is liked by other people,
            or you share the same concept, I will try my best to implement it.
            Do not discourage if someone already wrote the idea. If we see it more 
            times it shows that people really like it and it should be done. `,
            `Developing high-quality software is not a black-and-white affair,
            but an ongoing process. As developers, we should be prepared to
            perform multiple iterations and other adjustments based on the 
            analysis and feedback from others.`,
            `Please share your ideas in the comments bellow! :)`,
        ]
    },  
];

export default ideas;