import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const CreateAccountPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    
    const navigate = useNavigate();

    const createAccount = async () =>{
        try {
            if (password !== confirmPassword){
                setError('Password and confirm password do not match');
                return;
            }

            await createUserWithEmailAndPassword(getAuth(), email, password);
            navigate('/ideas');
        } catch (e) {
            setError(e.message);
        }
    }

    return (
        <>
        <h1>Create Account</h1>
        {error && <p className="error">{error}</p> }
        <br/>
        <label>Your email address</label>
        <input 
            placeholder="Your email address"
            value={email}
            onChange={e => setEmail(e.target.value)} />
        <label>Your password</label>
        <input 
            type="password"
            placeholder="Your password"
            value={password}
            onChange={e => setPassword(e.target.value)} />
        <label>Re-enter your password</label>
        <input 
            type="password"
            placeholder="Re-enter your password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)} />
        <button onClick={createAccount}>Create Account</button>
        <br/>
        <Link to="/login">Already have an account? Log in here</Link>
        </>
       
    );
}

export default CreateAccountPage;