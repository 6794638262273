import IdeasList from "../components/IdeasList";
import ideas from "./ideas-content";

const IdeasListPage = () =>{
    return(
        <>
        <h1>Ideas</h1>
        <IdeasList ideas={ideas} />
        </>
        
    );
}

export default IdeasListPage;