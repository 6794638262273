import Type from "../components/Type";
import { useNavigate } from "react-router-dom";

const HomePage = () => {

    let navigate = useNavigate(); 

  
    const goToAbout = () => {
        let path = `/about`; 
        navigate(path);
    }

    return(
        <>
            <div id="div-home" style={{textAlign: "center"}}>
                <h1>Hello, welcome to my page!</h1>
                <h2>
                    My name is Aleksandar
                </h2>
                <h2>
                    <Type />
                </h2>
                <button className="about-button" onClick={goToAbout}>
                MORE ABOUT ME
                </button>
               
                
            </div>
        </>
    );
}

export default HomePage;