import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import useUser from '../hooks/useUser.js';
import { toast } from "react-toastify";
import logo from './logopenchikj.png';

import { useState } from "react";
import { ReactComponent as CloseMenu } from "../assets/x.svg";
import { ReactComponent as MenuIcon } from "../assets/menu.svg";

const NavigationBar = () => {
    const { user } = useUser();
    const  navigate = useNavigate();
    
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);


    return(
        <nav>
            <a  href="/" className="nav-left alogo" tabIndex={0}>
                    <img  src={logo} className="logo" alt="Logo of the page."/>
            </a>
            <ul className={click ? "nav-menu active": "nav-menu"}>
                <li className="items" onClick={closeMobileMenu}>
                    <Link to="/" tabIndex={0}>Home</Link>
                </li>
                <li className="items" onClick={closeMobileMenu}>
                    <Link to="/about" tabIndex={0}>About</Link>
                </li>
                <li className="dropdown" tabIndex={0}>
                    <a className="dropbtn">
                        Apps
                    </a >
                    <div className="dropdown-content">
                        <Link to="/apps/weather" onClick={closeMobileMenu}>Weather</Link>
                        <Link to="/games3">TODO:App2</Link>
                        <Link to="/games3">TODO:App3</Link>
                    </div>
                </li>
                <li className="dropdown" tabIndex={0}>
                    <a className="dropbtn">
                        Games
                    </a>
                    <div className="dropdown-content" onClick={closeMobileMenu}>
                        <Link to="/games/tictactoe">Tic-Tac-Toe</Link>
                        <Link to="/games/pingpong">Ping-pong</Link>
                        <Link to="/games3">TODO:Game3</Link>
                    </div>
                </li>
                <li>
                    <Link to="/ideas" onClick={closeMobileMenu}>Ideas</Link>
                </li>
                <li>
                    <Link to="/contact" onClick={closeMobileMenu}>Contact</Link>
                </li>
                <div className="nav-right loginbtn">
                    { user 
                        ? <button onClick={()=>{
                            signOut(getAuth()).then(()=>{
                                // Sign-out successful.
                                toast("Log-out succesful!");
                            }).catch((error)=>{
                                // An error happened.
                                toast(error.message);
                            });
                        }}>Log Out</button>
                        : <button onClick={()=>{
                            navigate('/login');
                        }} > Log In</button>
                    }
                </div>
            </ul>
            <div className="mobile-menu" onClick={handleClick}>
                {click ? (
                <CloseMenu className="menu-icon" />
                ) : (
                <MenuIcon className="menu-icon" />
                )}
            </div>
        </nav>
    )
}

export default NavigationBar;