const Footer = () => {
    const year = new Date().getFullYear();

    return(
        <footer>
            <span className="footer-content">{`Copyright © ${year}. Made by Penchikj Aleksandar`}</span>
        </footer>
    );
}

export default Footer;