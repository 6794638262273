//import htmlPic from '../assets/icons/icons8-html-5.png' 

//inspiration: https://www.freecodecamp.org/news/how-to-build-a-developer-portfolio-website/

const TechnicalSkills = () => {
    return(
        <>
            <section class="skills" id="skills">
                <h2 class="skill-header">My Preferences</h2>

                <div class="skills-wrapper">
                  <div class="first-set animate__animated animate__zoomInDown">
                    <img
                        src={ require('../assets/icons/icons8-c-sharp.png')}
                        alt=""
                        loading="lazy"
                        class="icon icon-card"
                    />
                    <img
                        src={ require('../assets/icons/icons8-react.png')}
                        alt="https://icons8.com/icon/123603/react-native"
                        loading="lazy"
                        class="icon icon-card"
                    />
                    <img
                        src={ require('../assets/icons/icons8-unity.png')}
                        alt="https://icons8.com/icon/39848/unity"
                        loading="lazy"
                        class="icon icon-card"
                    />
                    <img
                        src={require('../assets/icons/icons8-kotlin.png')}
                        alt="https://icons8.com/icon/ZoxjA0jZDdFZ/kotlin"
                        loading="lazy"
                        class="icon icon-card"
                    />
                    <img
                        src={require('../assets/icons/icons8-nodejs.png')}
                        alt="https://icons8.com/icon/54087/nodejs"
                        loading="lazy"
                        class="icon icon-card"
                    />
                   

                    </div>
                </div>
            </section>
        </>
    );
}

export default TechnicalSkills;