import React, { useState, useEffect, useRef} from "react";
import "../components/PingPongGame/PingPong.css";
// PingPongGame.js



const PingPongPage = () => {
  const [score, setScore] = useState({ player1: 0, player2: 0 });
  const [paddle1Y, setPaddle1Y] = useState(40);
  const [paddle2Y, setPaddle2Y] = useState(40);
  const [ballSpeedX, setBallSpeedX] = useState(10);
  const [ballSpeedY, setBallSpeedY] = useState(8);
  const [ball, setBall] = useState({ x: 50, y: 50 });

  useEffect(() => {
    const gameInterval = setInterval(() => {
      moveBall();
    }, 50);

    return () => clearInterval(gameInterval);
  }, [ball]);

  useEffect(() => {
    console.log("--------------------------------------------------------");
   // setBallSpeedY(-ballSpeedY);
  }, [ballSpeedY]);



  const moveBall = () => {
    setBall(prevBall => {
      let newBallX = prevBall.x + ballSpeedX;
      let newBallY = prevBall.y + ballSpeedY;
  
      if (newBallX >= 980 || newBallX <= 20) {
        setBallSpeedX(ballSpeedX*(-1));
        newBallX = prevBall.x - ballSpeedX;
      }

  
      if (newBallY > 580 || newBallY <= 1) {
        setBallSpeedY(ballSpeedY*(-1));
        console.log(ballSpeedY);
        newBallY = prevBall.y - ballSpeedY;
      }
  
      if (newBallX < 31) {
        if (newBallY > paddle2Y && newBallY < paddle2Y + 120) {
          setBallSpeedX(ballSpeedX*(-1));
          newBallX = prevBall.x - ballSpeedX;
          console.log("+++++++++++++++++++++++++++++++++++");
        } else {
          setScore(prevScore => ({ ...prevScore, player2: prevScore.player2 + 1 }));
          resetBall();
        }
      }
      
      if (newBallX > 960) {
        if (newBallY > paddle1Y  && newBallY < paddle1Y + 120) {
          console.log("+++++++++++++++++++++++++++++++++++");
          setBallSpeedX(ballSpeedX*(-1));
          newBallX = prevBall.x - ballSpeedX;
        } else {
          setScore(prevScore => ({ ...prevScore, player1: prevScore.player1 + 1 }));
          resetBall();
        }
      }
  
      return { ...prevBall, x: newBallX, y: newBallY };
    });
  };
  
  
  

  const resetBall = () => {

    setBall({ x: 40, y: 40 }); 
    setBallSpeedX(10);
    setBallSpeedY(8);
  };


  const handleKeyDown = (e) => {
    if (e.code === "ArrowUp" && paddle1Y > 0) {
      setPaddle1Y(paddle1Y - 10);
    } else if (e.code === "ArrowDown" && paddle1Y < 480) {
      setPaddle1Y(paddle1Y + 10);
    }

    if (e.code === "KeyW" && paddle2Y > 0) {
      setPaddle2Y(paddle2Y - 10);
    } else if (e.code === "KeyS" && paddle2Y < 480) {
      setPaddle2Y(paddle2Y + 10);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [paddle1Y, paddle2Y]);

  //<div>X: {ball.x} Y: {ball.y} </div>
  return (
    <div className="game-container">
      <div className="game-score">
        <div>Player Red: {score.player1}</div>
        <div>Player Blue: {score.player2}</div>
        
      </div>
      <div className="game-board">
        <div
          className="game-ball"
          style={{ left: ball.x + "px", top: ball.y + "px" }}
        />
        <div
          className="game-paddle"
          style={{ top: paddle1Y + "px" }}
        />
        <div
          className="game-paddle"
          style={{ top: paddle2Y + "px" }}
        />
      </div>
      <div>| Use W/S key to move the red paddle, and use the Up/Down arrow to move the blue paddle |</div>
    </div>
  );
}  
          
export default PingPongPage;
