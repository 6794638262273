import React from 'react'
//import './../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './bootstrap.min.css.map'

// https://www.positronx.io/how-to-build-a-simple-contact-form-in-react-js-app/


const ContactForm = () => {
  const [formStatus, setFormStatus] = React.useState('Send')
  const onSubmit = async (e) => {
    e.preventDefault()
    setFormStatus('Submitting...')
    const { name, email, message } = e.target.elements
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    let response = await fetch(`${process.env.REACT_APP_API_URL}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setFormStatus("Send");
    let result = await response.json();
    alert(result.status);
  }
  return (
    <div className="container mt-5">
      <h2 className="mb-3">Get In Touch With Me</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label className="form-label" htmlFor="name">
            Name
          </label>
          <input className="form-control" type="text" id="name" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input className="form-control" type="email" id="email" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="message">
            Message
          </label>
          <textarea className="form-control" id="message" required />
        </div>
        <button className="btn btn-danger" type="submit">
          {formStatus.toUpperCase()}
        </button>
      </form>
    </div>
  )
}
export default ContactForm