import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
// @ts-ignore
//import { NotificationContainer, NotificationManager } from 'react-notifications';
//import notifier from 'simple-react-notifications';
import { toast } from "react-toastify";

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const logIn = async () => {
        try {
            await signInWithEmailAndPassword(getAuth(), email, password);
            navigate('/');
            //notifier.success("Your items have been updated");
            toast("Log-in succesful!");
        } catch (e){
            setError(e.message);
        }
        
    }



    return (
        <>
        <h1>Log In</h1>
        {error && <p className="error">{error}</p>}
        <br/>
        <label for="username">Your email address</label>
        <input 
            id="username"
            placeholder="Your email address"
            value={email}
            onChange={e => setEmail(e.target.value)} />
        <label for="password">Your password</label>
        <input 
            id="password"
            type="password"
            placeholder="Your password"
            value={password}
            onChange={e => setPassword(e.target.value)} />
        <button onClick={logIn}>Log In</button>
        <br/>
        <Link to="/create-account">Don't have an account? Create one here</Link>
        <br/>
        <p>*If you don't want to create account, <br/>
        feel free to use 'test123@gmail.com' <br/> 
        with password 'test123'</p>
        
        
        
        </>
       
    );
}

export default LoginPage;