import TechnicalSkills from "../components/TechnicalSkills";

const AboutPage = () => {
    return(
        <>
        <h1>About Me</h1>
        <p>
        Welcome to my page! I am a Developer with a bachelor's degree in Computer Science & Engineering.   
        I built this website to make information about me more accessible. At the same time, 
        the goal was to present my knowledge of web development and show my capabilities.
        <p>
        Here you can find useful apps and games. In the idea section, please feel free to make 
        any suggestions for further development. As much as I tried to make this web app bug-free, 
        I will be very grateful if you notice and report a bug. Don't hesitate to contact me for any project 
        or role you might offer via the contact form or any other communication channel.
        </p>
        I am seeking a position to expand my knowledge. I am eagerly looking for an intriguing opportunity
        in the Software Development industry, introducing me to new challenges and enabling me 
        to solve different tasks and problems.
        </p>

       
        <TechnicalSkills />
        
        <br></br>
        <h2>I can offer</h2>
        <ul>
            <li>Around a year of experience in software development, mainly in mobile and web technologies.</li>
            <li>Open to learning new technologies, best practices, and industry standards.</li>
            <li>Communication with stakeholders, analysing and meeting their needs and transforming their idea into a final product.</li>
            <li>Collaborate with other developers, designers, and cross-functional teams to contribute to team projects.</li>
            <li>Write and execute test cases, identify and fix bugs, troubleshoot issues and provide a solution.</li>
            <li>Receptive to feedback, willing to learn from more experienced team members, and maintain a constructive attitude even in challenging situations.</li>
            <li>Assist in the entire SDLC, from planning, analysis, design, and implementation to testing & integration, and maintenance.</li>
        </ul>

        <br></br>
        <h2>Programming languages / Tech Stack</h2>
        <ul>
            <li>C# / .NET</li>
            <li>Java</li>
            <li>React.js</li>
            <li>JavaScript</li>
            <li>Node.js</li>
            <li>Express.js</li>
            <li>T-SQL</li>
            <li>CSS</li>
            <li>HTML</li>
            <li>Unity</li>
            <li>MongoDB</li>
            <li>Kotiln</li>
        </ul>
        
        <br></br>
        <h2>IDEs / Tools</h2>
        <ul>
            <li>GitHub</li>
            <li>Visual Studio</li>
            <li>Gitlab</li>
            <li>Unity3D</li>
            <li>Android Studio</li>
            <li>Cisco Packet Tracer</li>
            <li>NetBeans</li>
            <li>AWS</li>
        </ul>
        
        <br></br>
        <h2>Work Experience</h2>
        <div>
            <div className="pastwork">Software Engineer, Salarify, Budapest <br/> Nov 2023-Jan 2024
                <br/>
                <p>Developed and maintained microservices using ASP.NET framework. Facilitated communication
                between services through service bus architecture using Azure Service Bus and RabbitMQ.
                JWT token authentication.</p>
            </div>
            <div className="pastwork">Product Support Engineer, SAP Kft., Budapest <br/> Jul 2022-present
                <br/>
                <p>Troubleshooting any issue that occurs in the SAP SCM system: Service Parts Planning, and 
                Demand Planning. Focusing on back-end problems, that will be thoroughly investigated and 
                resolved. The solving process includes analysis of the errors, research solutions, providing a 
                resolution and documentation of the solutions. Collaborating with a development support team 
                to identify and report bugs. Enhancing the knowledge of my area and maintaining the technical 
                documentation on the internal sites so a future colleague could adapt easily.</p>
            </div>
            <div className="pastwork">Software Developer, Sentice, Skopje <br/> Mar 2022-Jul 2022
                <br/>
                <p>Worked on the mobile app of an Attendance management system. Developed new features with 
                Kotlin, implementing stories, fixed bugs within an existing codebase, and do tasks modifying 
                the UI for better experience. Assisted in testing the new code.</p>
            </div>
            <div className="pastwork">Product Support Intern, SAP Kft., Budapest <br/> Sep 2021-Feb 2022
                <br/>
                <p> Worked on SCM Advanced Planning and Optimizer application. I worked 
                inside the Service Parts Planning component, where I was investigating and 
                diagnosing the problems within the code. I actively debugged ABAP programs 
                and find issues in order to provide a suitable solution.</p>
            </div>
        </div>

        <br></br>
        <h2>Education</h2>
        <div>
            <div className="pasteducation">John von Neumann Faculty of Informatics, Óbuda University, Budapest <br/> Sep 2018 - Jan 2022
                
                
                <br/>
                <p>Degree: B.Sc. Computer Science and Engineering <br/>
                Specialization: Cloud service technologies and IT security <br/>
                Thesis: Software module development for a robotic platform using artificial intelligence <br/>
                Final grade: 4.5/5

                </p>
            </div>            
        </div>

        
        </>
    );
}

export default AboutPage;